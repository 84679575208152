import {controller} from '@github/catalyst'
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import {createBrowserHistory} from './create-browser-history'
import type {EmbeddedData} from './embedded-data-types'
import {NavigatorClientEntry} from './NavigatorClientEntry'
import {
  getReactApp,
  isDataRouterApp,
  isNavigatorApp,
  type DataRouterAppRegistrationFn,
  type NavigatorAppRegistrationFn,
} from './react-app-registry'
import {ReactBaseElement} from './ReactBaseElement'
import {routesWithProviders} from './future/RoutesWithProviders'
import {v7_routeProviderFutureFlags, v7_routerFutureFlags} from './react-router-future-flags'

// What is this silliness? Is it react or a web component?!
// It's a web component we use to bootstrap react apps within the monolith.
@controller
export class ReactAppElement extends ReactBaseElement<EmbeddedData> {
  nameAttribute = 'app-name'

  async getReactNode(embeddedData: EmbeddedData, onError: (error: Error) => void): Promise<JSX.Element> {
    const reactApp = await getReactApp(this.name)

    if (isNavigatorApp(reactApp)) {
      return this.#getNavigatorNode(embeddedData, onError, reactApp.registration)
    }

    if (isDataRouterApp(reactApp)) {
      return this.#getDataRouterNode(embeddedData, onError, reactApp.registration)
    }

    // @ts-expect-error `type` should be exhausted an this shouldn't be possible
    throw new Error(`invalid app registration type ${reactApp.type}`)
  }

  async #getDataRouterNode(
    embeddedData: EmbeddedData,
    onError: (error: Error) => void,
    registration: DataRouterAppRegistrationFn,
  ) {
    const {routes} = registration()
    const router = createBrowserRouter(
      routesWithProviders(routes, {ssrError: this.ssrError, appName: this.name, wasServerRendered: this.hasSSRContent}),
      {future: v7_routerFutureFlags},
    )
    return <RouterProvider router={router} future={v7_routeProviderFutureFlags} />
  }

  async #getNavigatorNode(
    embeddedData: EmbeddedData,
    onError: (error: Error) => void,
    registration: NavigatorAppRegistrationFn,
  ) {
    const {App, routes} = registration()
    const initialPath = this.getAttribute('initial-path') as string

    if (this.isLazy) {
      const request = await fetch(initialPath, {
        mode: 'no-cors',
        cache: 'no-cache',
        credentials: 'include',
      })
      const {payload} = await request.json()

      embeddedData.payload = payload
    }

    const window = globalThis.window as Window | undefined

    // Initial path is set by ruby. Anchors are not sent to the server.
    // Therefore anchors must be set explicitly by the client.
    const {pathname, search, hash} = new URL(
      `${initialPath}${window?.location.hash ?? ''}`,
      window?.location.href ?? 'https://github.com',
    )

    const history = createBrowserHistory({window})
    const {key, state} = history.location
    const initialLocation = {
      pathname,
      search,
      hash,
      key,
      state,
    }

    return (
      <NavigatorClientEntry
        appName={this.name}
        initialLocation={initialLocation}
        history={history}
        embeddedData={embeddedData}
        routes={routes}
        App={App}
        wasServerRendered={this.hasSSRContent}
        ssrError={this.ssrError}
        onError={onError}
      />
    )
  }

  get isLazy() {
    return this.getAttribute('data-lazy') === 'true'
  }
}
