import {startSoftNav} from '@github-ui/soft-nav/state'
import {ssrSafeLocation} from '@github-ui/ssr-utils'
import {queryOptions} from '@tanstack/react-query'
import {generatePath, type PathParam} from 'react-router-dom'
import {getQueryClient} from '../query-client'
import {makeQueryKey} from '../query-key'
import {
  RouteQueryType,
  type BaseDataRoute,
  type QueryIndexRoute,
  type QueryNonIndexRoute,
  type QueryRoute,
  type IndexRouteConfiguration,
  type NonIndexRouteConfiguration,
  type RouteConfiguration,
} from './data-router-types'

const requestsInitiatedSoftNav = new WeakSet<Request>()

/**
 * Query route factory that transforms a route configuration object to a react-router-dom compatible route object
 */
export function queryRoute<DataRouterRouteObject extends BaseDataRoute = never>(
  appName: string,
  indexRoute: IndexRouteConfiguration<DataRouterRouteObject>,
): QueryIndexRoute<DataRouterRouteObject>
export function queryRoute<DataRouterRouteObject extends BaseDataRoute = never>(
  appName: string,
  nonIndexRoute: NonIndexRouteConfiguration<DataRouterRouteObject>,
): QueryNonIndexRoute<DataRouterRouteObject>
export function queryRoute<DataRouterRouteObject extends BaseDataRoute = never>(
  appName: string,
  dataRouteObject: RouteConfiguration<DataRouterRouteObject>,
): QueryRoute<DataRouterRouteObject> {
  const {queries, Component, ...route} = dataRouteObject
  return {
    ...route,
    Component,
    loader: async ({request, params}) => {
      /**
       * Slight hack here, the 'http' request is the same object sent to every loader
       * so we can store it and only `startSoftNav` on the first call, instead of on
       * every loader call
       */
      if (!requestsInitiatedSoftNav.has(request)) {
        startSoftNav('react')
        requestsInitiatedSoftNav.add(request)
      }

      const blockingRequests: Array<Promise<unknown>> = []
      const {searchParams} = new URL(request.url, ssrSafeLocation.origin)

      const routePathWithParams = generatePath(
        route.path,
        params as {[key in PathParam<DataRouterRouteObject['path']>]: string | null},
      ) as `/${string}`
      /**
       * Object.entries will lose type safety here, so we're being a bit casty to make it inferrable inside the map below
       */
      const queryEntries = Object.entries(queries) as Array<
        [keyof typeof queries, (typeof queries)[keyof typeof queries]]
      >
      const queryConfigs = queryEntries.map(([queryName, {queryFn, queryDeps, type, ...config}]) => {
        const deps = queryDeps?.({path: routePathWithParams, params, searchParams}) ?? {}
        const queryConfig = queryOptions({
          queryKey: makeQueryKey({
            appName,
            route: {path: route.path, index: route.index},
            queryName: queryName.toString(),
            queryDeps: deps,
          }),
          queryFn: ({signal, meta}) => {
            return queryFn(deps, {signal, meta})
          },
          ...(config as Omit<Parameters<typeof queryOptions>[0], 'queryFn' | 'queryKey'>),
        })

        switch (type) {
          case RouteQueryType.Blocking: {
            const query = getQueryClient().fetchQuery(queryConfig)
            blockingRequests.push(query)
            break
          }
          case RouteQueryType.DeferredWithPrefetch: {
            /** Deferred queries use prefetch to avoid throwing/rejecting on failure */
            void getQueryClient().prefetchQuery(queryConfig)
            break
          }
        }

        return [queryName, {queryConfig, type}] as const
      })

      await Promise.all(blockingRequests)

      return {
        route: dataRouteObject,
        queries: Object.fromEntries(queryConfigs),
      }
    },
    isSameRoute(routeToTest) {
      return Object.is(dataRouteObject, routeToTest)
    },
  }
}
