import {
  useQuery,
  type DefinedUseQueryResult,
  type SkipToken,
  type UseQueryOptions,
  type UseQueryResult,
} from '@tanstack/react-query'
import {useLoaderData} from 'react-router-dom'
import type {RouteQueryKey} from '../query-key'
import type {BaseDataRoute, QueryRoute, RouteConfiguration, RouteQueryType} from './data-router-types'

type QueryOptionsWithKey = UseQueryOptions<unknown, Error, unknown> & {
  initialData?: unknown
} & {
  queryKey: RouteQueryKey
  queryFn: Exclude<UseQueryOptions<unknown, Error, unknown>['queryFn'], SkipToken>
}

/**
 * Returns the query configurations for a given route.
 * If called from the wrong route, throws an error.
 */
export function useQueriesConfigs<DataRouterRouteObject extends BaseDataRoute>(
  routeArg: QueryRoute<DataRouterRouteObject>,
): Record<keyof DataRouterRouteObject['queries'], {type: RouteQueryType; queryConfig: QueryOptionsWithKey}> {
  const {route, queries} = useLoaderData() as {
    route: RouteConfiguration<DataRouterRouteObject>
    queries: Record<keyof DataRouterRouteObject['queries'], {type: RouteQueryType; queryConfig: QueryOptionsWithKey}>
  }
  if (!routeArg.isSameRoute(route)) throw new Error('invalid route check')
  return queries
}

/**
 * Returns a single query configuration by name
 */
export function useQueriesConfig<
  DataRouterRouteObject extends BaseDataRoute,
  QueryName extends keyof DataRouterRouteObject['queries'],
>(
  route: QueryRoute<DataRouterRouteObject>,
  queryName: QueryName,
): {type: RouteQueryType; queryConfig: QueryOptionsWithKey} {
  const ctx = useQueriesConfigs(route)
  return ctx[queryName]
}

/**
 * Given a names route query and a route, returns the
 * result of calling useQuery on the generated route config.
 *
 * Overrides for query configuration can be optionally passed as a third argument
 *
 * When called on a blocking route, data is always defined.
 * When called on a deferred route, data is potentially undefined.
 */
export function useRouteQuery<
  DataRouterRouteObject extends BaseDataRoute,
  QueryName extends keyof DataRouterRouteObject['queries'],
>(
  route: QueryRoute<DataRouterRouteObject>,
  queryName: QueryName,
  queryOverrides?: Omit<
    UseQueryOptions<DataRouterRouteObject['queries'][QueryName]['response']>,
    'queryKey' | 'queryFn'
  >,
) {
  type ThisQueryConfig = DataRouterRouteObject['queries'][QueryName]
  if (typeof queryName !== 'string') throw new Error('queryName must be a string')
  const {queryConfig} = useQueriesConfig(route, queryName)
  /**
   * If we have a deferred query we don't have initial data always defined, so it potentially
   * could render with `undefined`.  When we have a blocking query, the loader awaits the data
   * so it should never be undefined
   */
  return {...useQuery({...queryConfig, ...queryOverrides}), queryKey: queryConfig.queryKey} as UseRouteQueryResult<
    ThisQueryConfig['response'],
    ThisQueryConfig['type']
  > & {queryKey: RouteQueryKey}
}

/**
 * This type returns a version of `UseQuery` that considers `initialData` as defined when we have a blocking query
 * otherwise assumes initialData was undefined for deferred queries
 *
 * It uses the return types of `useQuery` in each of these cases, however typescript can't easily infer this
 * for us because of how generic the responses from `useQueriesConfig` is.
 */
type UseRouteQueryResult<Result, Type extends RouteQueryType> = Type extends typeof RouteQueryType.Blocking
  ? DefinedUseQueryResult<Awaited<Result>>
  : UseQueryResult<Awaited<Result>>
