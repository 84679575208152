interface QueryKeyParams {
  appName: string
  route: {path: string; index?: boolean}
  queryName: string
  queryDeps: Record<string, unknown>
}

// Since the queryDeps object is optional, we exclude it from the QueryKey type if it is not provided or mark as non-nullable otherwise.
export type RouteQueryKey = [
  QueryKeyParams['appName'],
  QueryKeyParams['route'],
  QueryKeyParams['queryName'],
  QueryKeyParams['queryDeps'],
]

/**
 * Generates an app-unique react-query query key based on the data provided.
 * This is used to interact with the query client that powers queryRoute.
 * @param appName Name of the app that the query is for. This is the top level name of the react app.
 * @param route The route that the current router matched. Note that this is a path match (like /orgs/:repoId/:commentId) and index, not a full path (i.e. /orgs/143/65).
 * @param queryName The key of the QueryConfig in the route.queries object in the query route registration.
 * @param queryDeps An object that uniquely identifies a query's fetch function. By default, it is a combined object of route and search params
 * (i.e. /orgs/:repoId/:commentId?q=search would be {routeParams: { repoId: '143', commentId: '65' }, searchParams{ q: 'search' }}).
 * @returns A unique query key that can be used to interact with the query client. In the form of [appName, path, queryName, queryDeps?].
 */
export function makeQueryKey({
  appName,
  route: {path, index = false},
  queryName,
  queryDeps,
}: QueryKeyParams): RouteQueryKey {
  return [appName, {path, index}, queryName, queryDeps] as const
}
