import type {RouteObject} from 'react-router-dom'
import type {DataRouterAppRegistrationFn} from '../react-app-registry'
import type {
  BaseDataRoute,
  QueryIndexRoute,
  QueryNonIndexRoute,
  QueryRoute,
  IndexRouteConfiguration,
  NonIndexRouteConfiguration,
  RouteConfiguration,
} from './data-router-types'
import {queryRoute} from './QueryRoute'

export class DataRouterApplication<T extends string> {
  declare readonly name: T
  #routes: RouteObject[]

  constructor(name: T, routes: RouteObject[]) {
    this.name = name
    this.#routes = routes
    this.registration = this.registration.bind(this)
  }

  registration(): ReturnType<DataRouterAppRegistrationFn> {
    return {
      routes: this.#routes,
    }
  }
}

export class DataRouterApplicationBuilder<T extends string> {
  declare readonly name: T

  constructor(name: T) {
    this.name = name
  }

  createQueryRoute<DataRouterRouteObject extends BaseDataRoute = never>(
    route: IndexRouteConfiguration<DataRouterRouteObject>,
  ): QueryIndexRoute<DataRouterRouteObject>
  createQueryRoute<DataRouterRouteObject extends BaseDataRoute = never>(
    route: NonIndexRouteConfiguration<DataRouterRouteObject>,
  ): QueryNonIndexRoute<DataRouterRouteObject>
  createQueryRoute<DataRouterRouteObject extends BaseDataRoute = never>(
    route: RouteConfiguration<DataRouterRouteObject>,
  ): QueryRoute<DataRouterRouteObject> {
    // split these code-paths to make typescript happy, otherwise we can't discriminate over the valid signatures
    if (route.index) {
      return queryRoute(this.name, route)
    }
    return queryRoute(this.name, route)
  }

  createDataRouterAppFromRoutes(routes: RouteObject[]) {
    return new DataRouterApplication(this.name, routes)
  }

  static create<T extends string>(name: T) {
    return new DataRouterApplicationBuilder<T>(name)
  }
}
