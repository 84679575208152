import type {QueryMeta, useQuery} from '@tanstack/react-query'
import type {IndexRouteObject, NonIndexRouteObject, Params, PathParam, RouteObject} from 'react-router-dom'

export type BaseDataRoute = {
  path: `/${string}`
  queries: {
    [queryName: string]: {
      deps?: Record<string, unknown> | null | never
      response: unknown
      type: RouteQueryType
    }
  }
}

export const RouteQueryType = {
  /**
   * A blocking query will resolve _prior to navigation_
   */
  Blocking: 'Blocking',
  /**
   * A DeferredWithPrefetch query will begin during navigation, but may resolve after navigation completes
   */
  DeferredWithPrefetch: 'DeferredWithPrefetch',
  /**
   * A DeferredWithoutPrefetch query will not begin during navigation.
   *
   * It can be initiated manually, or by calling `useRouteQuery` directly, and resolves whenever it can
   */
  DeferredWithoutPrefetch: 'DeferredWithoutPrefetch',
} as const
export type RouteQueryType = (typeof RouteQueryType)[keyof typeof RouteQueryType]

type BaseQueryRoute<DataRouterRouteObject extends BaseDataRoute = never> = {
  isSameRoute: (routeToTest: RouteConfiguration<DataRouterRouteObject>) => boolean
}

type BaseRouteConfiguration<DataRouterRouteObject extends BaseDataRoute = never> = Pick<
  RouteObject,
  'errorElement' | 'hydrateFallbackElement' | 'id' | 'lazy' | 'shouldRevalidate' | 'HydrateFallback' | 'ErrorBoundary'
> & {
  path: DataRouterRouteObject['path']
  Component: React.ComponentType
  queries: {
    [Key in keyof DataRouterRouteObject['queries']]: QueryConfig<{
      path: DataRouterRouteObject['path']
      deps: DataRouterRouteObject['queries'][Key]['deps']
      response: DataRouterRouteObject['queries'][Key]['response']
      type: DataRouterRouteObject['queries'][Key]['type']
    }>
  }
}

type BaseQueryConfig<Type extends RouteQueryType> = {
  type: Type
}

/**
 * When the queryDeps are required, they must be returned from the queryDeps function and are accessible in the queryFn
 */
type QueryConfigWithRequiredDeps<
  Path extends string,
  Res,
  Type extends RouteQueryType,
  Deps,
> = BaseQueryConfig<Type> & {
  queryDeps: (args: {path: string; params: Params<PathParam<Path>>; searchParams: URLSearchParams}) => Deps
  queryFn: (deps: Deps, opts: {signal: AbortSignal; meta: QueryMeta | undefined}) => Res | Promise<Res>
}

/**
 * When the queryDeps function is Optional it must not return any key/value pairs if it's defined
 */
type QueryConfigWithOptionalDeps<Path extends string, Res, Type extends RouteQueryType> = BaseQueryConfig<Type> & {
  queryDeps?: (args: {
    path: string
    params: Params<PathParam<Path>>
    searchParams: URLSearchParams
  }) => Record<string, never>
  queryFn: (deps: Record<string, never>, opts: {signal: AbortSignal; meta: QueryMeta | undefined}) => Res | Promise<Res>
}

type AtLeastOneKey<T> = T extends Record<string, never> ? never : T

type QueryConfig<
  Config extends {
    path: `/${string}`
    deps?: Record<string, unknown> | null | never
    response: unknown
    type: RouteQueryType
  },
> = Omit<Parameters<typeof useQuery>[0], 'queryKey' | 'queryFn'> &
  // when we have a config that contains deps that are an object
  Config extends {deps: Record<string, unknown>}
  ? // and if at least one key exists in that deps object
    Config['deps'] extends AtLeastOneKey<Config['deps']>
    ? // then require queryDeps function to be defined and return an object that matches it
      QueryConfigWithRequiredDeps<Config['path'], Config['response'], Config['type'], Config['deps']>
    : // else queryDeps can be undefined, or return an empty object
      QueryConfigWithOptionalDeps<Config['path'], Config['response'], Config['type']>
  : // else queryDeps can be undefined, or return an empty object
    QueryConfigWithOptionalDeps<Config['path'], Config['response'], Config['type']>

export type QueryIndexRoute<DataRouterRouteObject extends BaseDataRoute = never> =
  BaseQueryRoute<DataRouterRouteObject> & IndexRouteObject

export type QueryNonIndexRoute<DataRouterRouteObject extends BaseDataRoute = never> =
  BaseQueryRoute<DataRouterRouteObject> & NonIndexRouteObject

export type QueryRoute<DataRouterRouteObject extends BaseDataRoute = never> = BaseQueryRoute<DataRouterRouteObject> &
  RouteObject

export type IndexRouteConfiguration<DataRouterRouteObject extends BaseDataRoute = never> =
  BaseRouteConfiguration<DataRouterRouteObject> & Pick<IndexRouteObject, 'children' | 'index'>

export type NonIndexRouteConfiguration<DataRouterRouteObject extends BaseDataRoute = never> =
  BaseRouteConfiguration<DataRouterRouteObject> & Pick<NonIndexRouteObject, 'index'> & {children?: RouteObject[]}

export type RouteConfiguration<DataRouterRouteObject extends BaseDataRoute = never> =
  | IndexRouteConfiguration<DataRouterRouteObject>
  | NonIndexRouteConfiguration<DataRouterRouteObject>
